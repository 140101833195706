import axios from "axios";
import { TIME_OUT } from "./config";
// Declare a Map to store the id and cancel function for each request
const pending = new Map();

const instance = axios.create({
   headers: {
      'content-type': 'application/x-www-form-urlencoded',
   },
   baseURL: process.env.REACT_APP_BASE_URL,
   timeout: TIME_OUT,
});
instance.interceptors.request.use(
   (config) => {
      if(window.localStorage.getItem('token')){
         config.headers['token'] = window.localStorage.getItem('token')
      }
      removePending(config); // Before the request starts, check the previous request to cancel the operation
      addPending(config); // Adds the current request to pending
      return config;
   },
   (err) => {
      return Promise.reject(err);
   }
);

instance.interceptors.response.use(
   (res) => {
      removePending(res); // When the request is finished, remove the request
      return res.data;
   },
   (err) => {
      if (axios.isCancel(err)) {
         console.log("repeated request: " + err.message);
      } else {
         if (err && err.response) {
            switch (err.response.status) {
               case 400:
                  console.log("Request failed");
                  break;
               case 401:
                  console.log("without authorization");
                  break;
               default:
                  console.log("Other error messages");
            }
         }
      }
      return Promise.reject(err);
   }
);

/**
 * Add the request
 * @param {Object} config
 */
const addPending = (config) => {
   const url = [config.method, config.url].join("&");
   config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
         if (!pending.has(url)) {
            // If the current request does not exist in pending, it is added
            pending.set(url, cancel);
         }
      });
};
/**
 * Remove request
 * @param {Object} config
 */
export const removePending = (config) => {
   const url = [config.method, config.url].join("&");
   if (pending.has(url)) {
      // If the current request ID exists in pending, cancel the current request and remove it
      const cancel = pending.get(url);
      cancel(url);
      pending.delete(url);
   }
};
/**
 * If the current request ID exists in pending, cancel the current request and remove it
 */
export const clearPending = () => {
   for (const [url, cancel] of pending) {
      cancel(url);
   }
   pending.clear();
};
export default instance;
