import { Global } from "@emotion/react";
import * as React from "react";

export const ResetCSS = () => (
  <Global
    styles={`
      html{
        overflow-y: overlay;
       }
    body,
    textarea,
    select,
    input,
    button {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
     }
     input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{
      -webkit-appearance:textfield;
  }
  input[type="number"]{
      -moz-appearance:textfield;
  }
    ::-webkit-scrollbar {
      width: 6px;
    }
    /* Rolling Slot */
    ::-webkit-scrollbar-track {
      box-shadow: inset006pxrgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
    /* Scroll Bar Slider */
    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(122, 122, 122, 0.2);
    }
    `}
  />
);

export default ResetCSS;
