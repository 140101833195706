import { Icon } from "@chakra-ui/react";

export const LogoutIcon = (props) => (
   <Icon viewBox="0 0 1024 1024" {...props}>
      <path
         fill="currentColor"
         d="M192.192 1024A192.128 192.128 0 0 1 0 832V192c0-106.048 86.08-192 192.192-192h383.68a64 64 0 1 1 0 128H192.192a64 64 0 0 0-64 64v640a64 64 0 0 0 64 64h383.68a64 64 0 1 1 0 128H192.192z m545.472-289.6a64 64 0 0 1 0-90.432l67.84-67.904H384.128a64 64 0 1 1 0-128h421.376l-82.752-82.816a64 64 0 0 1 90.432-90.496l192.192 192a64.512 64.512 0 0 1 0 90.496l-177.088 177.152c-24.96 24.96-65.408 24.96-90.432 0h-0.192z"
      />
   </Icon>
);
