import { isLogin } from "@/utils/tools";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const { useEffect } = require("react");

function loginAuth(WrapperComponent, needLogin) {
   return function PureComponent() {
      const {twitter_screen_name,id ,vip_level,vip_expire_sec} = useSelector(
         (state) => ({
            twitter_screen_name: state.user.twitter_screen_name,
            id: state.user.id,
            vip_expire_sec:state.user.vip_expire_sec,
            vip_level:state.user.vip_level
         }),
         shallowEqual
      );
      const token = window.localStorage.getItem('token')
      const location = useLocation()
      const navigate = useNavigate();
      useEffect(() => {
         if(needLogin){
            if(!isLogin(token, id)){
               navigate("/", { replace: true });
            }else if(!twitter_screen_name||location.pathname.indexOf(`${twitter_screen_name}/`)===-1){
               navigate("/", { replace: true });
            }
         }
      }, [token, id, twitter_screen_name,location.pathname,navigate]);
      if (needLogin && !isLogin(token, id)) {
         return false;
      } else {
         return <WrapperComponent isLogin={isLogin(token, id)} user_id={id} isVip={vip_expire_sec>0} vip_level={vip_level} />;
      }
   };
}
export default loginAuth;
