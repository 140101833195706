const Text = {
   variants: {
      bold: {
          fontFamily:'Arial-BoldMT',
      },
      normal: {
      },
      "12_mgray": {
         fontSize: 12,
         color: "#999",
      },
      "12_gray": {
         fontSize: 12,
         color: "#666",
      },
      "12_lblack": {
         fontSize: 12,
         color: "#333",
      },
      "14_black": {
         fontSize: 14,
         color: "black",
      },
      "14_lblack": {
         fontSize: 14,
         color: "#333",
      },
      "14_lblack_bold": {
         fontSize: 14,
         color: "#333",
         fontFamily:'Arial-BoldMT'
      },
      "14_mgray":{
         fontSize:14,
         color:'#999'
      },
      "14_mgray_bold":{
         fontSize:14,
         color:'#999',
         fontFamily:'Arial-BoldMT',
      },
      "14_lgray": {
         fontSize: 14,
         color: "#ccc",
      },
      "14_gray":{
         fontSize:14,
         color: "#666"
      },
      "14_alohagreen":{
         fontSize:14,
         color:'#24D698'
      },
      "14_white":{
         fontSize:14,
         color: "#FFF"
      },
      "14_black_bold": {
         fontSize: 14,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
      "14_white_bold": {
         fontSize: 14,
         color: "white",
          fontFamily:'Arial-BoldMT',
      },
      
      "16_black": {
         fontSize: 16,
         color: "black",
         
      },
      "16_white": {
         fontSize: 16,
         color: "white",
         
      },
      "16_lblack": {
         fontSize: 16,
         color: "#333",
         
      },    
      "16_gray": {
         fontSize: 16,
         color: "#666",
         
      },
      "16_gray_bold": {
         fontSize: 16,
         color: "#666",
          fontFamily:'Arial-BoldMT',
      },

      "16_black_bold": {
         fontSize: 16,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
      "16_lblack_bold": {
         fontSize: 16,
         color: "#333",
          fontFamily:'Arial-BoldMT',
      },
      "18_lblack_bold": {
         fontSize: 16,
         color: "#333",
          fontFamily:'Arial-BoldMT',
      },
      "16_white_bold": {
         fontSize: 16,
         color: "white",
          fontFamily:'Arial-BoldMT',
      },
      "18_black":{
         fontSize:18,
         color: 'black'
      },
      "20_black": {
         fontSize: 20,
         color: "black",
         
      },
      "20_gray": {
         fontSize: 20,
         color: "#666",
         
      },
      "20_black_bold": {
         fontSize: 20,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
      "20_lblack_bold": {
         fontSize: 20,
         color: "#333",
          fontFamily:'Arial-BoldMT',
      },
      "22_white_bold": {
         fontSize: 22,
         color: "white",
          fontFamily:'Arial-BoldMT',
      },
      "24_black_bold": {
         fontSize: 24,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
      "26_mgray_bold": {
         fontSize: 26,
         color: "#999",
          fontFamily:'Arial-BoldMT',
      },
      "26_white_bold": {
         fontSize: 26,
         color: "white",
          fontFamily:'Arial-BoldMT',
      },
      "26_black_bold": {
         fontSize: 26,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
      "30_black": {
         fontSize: 30,
         color: "black",
         
      },
      "30_black_bold": {
         fontSize: 30,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
      "30_lblack_bold": {
         fontSize: 30,
         color: "#333",
          fontFamily:'Arial-BoldMT',
      },
      "34_black": {
         fontSize: 34,
         color: "black",
         
      },
      "50_black_bold": {
         fontSize: 50,
         color: "black",
          fontFamily:'Arial-BoldMT',
      },
   },
   // The default size and variant values
   defaultProps: {
      size: "md",
      variant: "normal",
   },
};
export default Text;
