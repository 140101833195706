import { CHANGE_SELECT_BOT_ID, SET_BOT_CONFIG_LIST } from "./constants";
const defaultState = {
   list:[],
   currentBotId:0
}
function botlistReducer(state = defaultState, action) {
   switch (action.type) {
      case SET_BOT_CONFIG_LIST:
         if(action.res===null){
            return defaultState
         }else{
            return {...state,list:action.res}
         }
         case CHANGE_SELECT_BOT_ID:
            return {...state,currentBotId:action.res}
      default:
         return state;
   }
}
export default botlistReducer;
