import { SET_BID_CONFIG_LIST } from "./constants";
const defaultState = {
   list:[]
}
function bidReducer(state = defaultState, action) {
   switch (action.type) {
      case SET_BID_CONFIG_LIST:
         if(action.res===null){
            return defaultState
         }else{
            return {...state,list:action.res}
         }
      default:
         return state;
   }
}
export default bidReducer;
