import React from "react";
// Import Swiper React components

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import "swiper/modules/pagination/pagination.min.css";

// import required modules
import "./styles.css";
import HomeHeaderComponent from "./components/header";
import { useLocation } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import RouterConfig from "./router";
export default function App() {
   const location = useLocation();
   const bBlueBg = location.pathname.endsWith("getstart");
   return (
      <Flex flexDir={"column"} minH={"100vh"} backgroundColor={bBlueBg ? "#F0F7FA" : "white"}>
         <HomeHeaderComponent />
         <RouterConfig />
      </Flex>
   );
}
