import { Icon } from "@chakra-ui/react";

export const CloseIcon = (props) => (
   <Icon viewBox="0 0 1024 1024" {...props}>
      <path
         d="M856.589374 947.066936m-45.254834-45.254834l-724.077343-724.077344q-45.254834-45.254834 0-90.509668l0 0q45.254834-45.254834 90.509668 0l724.077343 724.077344q45.254834 45.254834 0 90.509668l0 0q-45.254834 45.254834-90.509668 0Z"
         fill="currentColor"
      />
      <path
         d="M132.512031 947.066936m-45.254834-45.254834l0 0q-45.254834-45.254834 0-90.509668l724.077343-724.077344q45.254834-45.254834 90.509668 0l0 0q45.254834 45.254834 0 90.509668l-724.077343 724.077344q-45.254834 45.254834-90.509668 0Z"
         fill="currentColor"
      />
   </Icon>
);
