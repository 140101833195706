import { getUserInfo } from "@/services/user"
import { SET_USER_INFO, SET_USER_LEVEL } from "./constants"

export const getUserInfoAction = (user_id) =>{
    return (dispatch)=>{
        getUserInfo(user_id).then(res=>{
            if(res.code === 0){
                dispatch(setUserInfoAction(res.data))
            }else{
                dispatch(setUserInfoAction(null))
            }
        })
    }
}

export const setUserInfoAction = (res)=>({
    type:SET_USER_INFO,
    res
})

export const setUserInfoLevelAction = (res)=>({
    type:SET_USER_LEVEL,
    res
})

