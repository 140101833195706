import {
   Button,
   Center,
   Collapse,
   Divider,
   Flex,
   Hide,
   Image,
   Show,
   Spacer,
   Text,
   useBoolean,
   useToast,
} from "@chakra-ui/react";
import { DivBox } from "./style";
import React, { memo, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setUserInfoAction } from "../profile/store/action";
import loginAuth from "../custom/login-auth";
import PopOverComponent from "./popover";
import { MoreIcon } from "@/icon/MoreIcon";
import { CloseIcon } from "@/icon/CloseIcon";
import { setBotConfigListAction } from "../yourbots/store/action";
import { LogoutIcon } from "@/icon/LogoutIcon";
import { LogIcon } from "@/icon/LogIcon";
import { getTwitterUrl, getUserInfo } from "@/services/user";
import { setBidConfigListAction } from "../yourbots/bid/store/action";
import { FROM_NORMAL } from "../getstart/constants";
function HomeHeaderComponent({ user_id, isLogin }) {
   const location = useLocation();
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const twitter_screen_name = useSelector((state) => state.user.twitter_screen_name, shallowEqual);
   const bHomePage = location.pathname === "/";
   const bGetStart = location.pathname.indexOf("getstart") !== -1;
   const bDocPage = location.pathname.indexOf("doc") !== -1;
   const [showMenu, setMenuShow] = useBoolean();
   const toast = useToast();
   const [isLoading, setLoading] = useBoolean();

   useEffect(() => {
      if (!bHomePage || !location.state) {
         setMenuShow.off();
      }
   }, [location.pathname, setMenuShow, bHomePage, location.state]);
   function connectTwitter() {
      setLoading.on();
      window.localStorage.setItem('LOGIN_ACTION',location.state?location.state.version:FROM_NORMAL)
      getTwitterUrl()
         .then((res) => {
            window.location.replace(res.data.url);
         })
         .catch((err) => {
            setLoading.off();
         });
   }
   useEffect(() => {
      if (isLogin) {
         async function fetchData() {
            const res = await getUserInfo(user_id);
            if (res.code === 0) {
               dispatch(setUserInfoAction(res.data));
            } else {
               dispatch(setUserInfoAction(null));
               toast({
                  title: "Login expired, please login again",
                  status: "error",
                  position: "top",
               });
            }
         }
         fetchData();
      }
   }, [dispatch, user_id, isLogin, toast]);
   function showLoginedComponent() {
      return (
         <DivBox>
            <NavLink className={"navlink"} to={`/${twitter_screen_name}/mybot`}>
               Your Bots
            </NavLink>
            <NavLink
               to={"/"}
               state={{ jump: true, type: "price" }}
               color="#000"
               style={{ fontSize: "18px", marginLeft: "66px" }}>
               Pricing
            </NavLink>
            <PopOverComponent />
         </DivBox>
      );
   }
   function showNotLoginComponent() {
      return (
         <>
            <NavLink to={"/"} state={{ jump: true, type: "example" }} color="#000" style={{ fontSize: "18px" }}>
               Examples
            </NavLink>
            <NavLink
               to={"/"}
               state={{ jump: true, type: "price" }}
               color="#000"
               style={{ fontSize: "18px", marginLeft: "66px" }}>
               Pricing
            </NavLink>
            {bGetStart ? (
               <Button
                  ml={66}
                  fontSize={16}
                  colorScheme={"green"}
                  boxShadow={"0px 16px 30px 0px rgba(201,239,226,0.8)"}
                  borderRadius={25}
                  h={"50px"}
                  loadingText="Connecting"
                  isLoading={isLoading}
                  w={"140px"}
                  onClick={connectTwitter}
                  color={"#FFF"}>
                  Connect
               </Button>
            ) : (
               <NavLink to={"/getstart"} state={{ version: FROM_NORMAL }}>
                  <Button
                     ml={66}
                     fontSize={16}
                     colorScheme={"green"}
                     boxShadow={"0px 16px 30px 0px rgba(201,239,226,0.8)"}
                     borderRadius={25}
                     h={"50px"}
                     w={"140px"}
                     color={"#FFF"}>
                     Get Started
                  </Button>
               </NavLink>
            )}
         </>
      );
   }

   if (bDocPage) {
      return false;
   }
   return (
      <>
         <Flex
            flexDir={"column"}
            boxShadow={
               (bHomePage || bGetStart) && (!showMenu || !isLogin) ? "none" : "0px 2px 10px 0px rgba(0,0,0,0.05)"
            }>
            <Flex
               px={{ base: "20px", BREAK_LPC: "30px" }}
               h={{ base: "80px", BREAK_LPC: "86px" }}
               w={"100%"}
               alignItems={"center"}
               justifyContent={"center"}
               marginX={"auto"}>
               <NavLink to={"/"}>
                  <Hide above="BREAK_LPC">
                     {isLogin ? (
                        <LogIcon boxSize={"40px"} />
                     ) : (
                        <Image
                           alt="logo"
                           userSelect={"none"}
                           src={process.env.REACT_APP_PUBLIC_URL + "iconfont/logo_icon.svg"}
                           w={{ base: "154px", BREAK_LPC: "184px" }}
                           h={{ base: "40px", BREAK_LPC: "48px" }}
                        />
                     )}
                  </Hide>
                  <Show above="BREAK_LPC">
                     <Image
                        alt="4"
                        userSelect={"none"}
                        src={process.env.REACT_APP_PUBLIC_URL + "iconfont/logo_icon.svg"}
                        w={{ base: "154px", BREAK_LPC: "184px" }}
                        h={{ base: "40px", BREAK_LPC: "48px" }}
                     />
                  </Show>
               </NavLink>
               <Spacer />
               <Show above={"BREAK_LPC"}>{isLogin ? showLoginedComponent() : showNotLoginComponent()}</Show>
               <Hide above={"BREAK_LPC"}>
                  <Center
                     cursor={"pointer"}
                     onClick={(e) => {
                        setMenuShow.toggle();
                     }}>
                     {showMenu ? (
                        <CloseIcon color="#999" boxSize={"18px"} />
                     ) : (
                        <MoreIcon color="#999" boxSize={"18px"} />
                     )}
                  </Center>
               </Hide>
            </Flex>
            <Hide above={"BREAK_LPC"}>
               <Collapse in={showMenu} animateOpacity>
                  <Flex
                     flexDir={"column"}
                     borderBottom={isLogin ? "0px solid" : "1px solid"}
                     borderTop={isLogin ? "1px solid" : "0px solid"}
                     borderColor={"#F1F1F1"}
                     outline={"none"}
                     _focus={{ boxShadow: "none" }}>
                     <Flex flexDir={"column"} px={"20px"}>
                        {isLogin ? (
                           <>
                              <Text
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="#333"
                                 h={"50px"}
                                 px={"16px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    setMenuShow.off();
                                    navigate(`/${twitter_screen_name}/mybot`);
                                 }}>
                                 Your Bots
                              </Text>
                              <Divider />
                              <Text
                                 px={"16px"}
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="#333"
                                 h={"50px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    if (bHomePage) {
                                       navigate("/", { state: { jump: true, type: "price" }, replace: true });
                                    } else {
                                       navigate("/", { state: { jump: true, type: "price" } });
                                    }
                                 }}>
                                 Pricing
                              </Text>
                              <Divider />
                              <Text
                                 px={"16px"}
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="#333"
                                 h={"50px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    setMenuShow.off();
                                    navigate(`/${twitter_screen_name}/profile`);
                                 }}>
                                 Profile
                              </Text>
                              <Divider />
                              <Text
                                 px={"16px"}
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="#333"
                                 h={"50px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    setMenuShow.off();
                                    navigate(`/${twitter_screen_name}/orderrecord`);
                                 }}>
                                 Order Record
                              </Text>
                              <Divider />
                              <Flex
                                 alignItems={"center"}
                                 onClick={(_e) => {
                                    setMenuShow.off();
                                    navigate("/", { replace: true });
                                    dispatch(setUserInfoAction(null));
                                    dispatch(setBotConfigListAction(null));
                                    dispatch(setBidConfigListAction(null));
                                 }}
                                 ml={"16px"}
                                 h={"50px"}>
                                 <LogoutIcon boxSize={"14px"} color="#999" />
                                 <Text fontSize={14} ml={"12px"} color="#333">
                                    Logout
                                 </Text>
                              </Flex>
                           </>
                        ) : (
                           <>
                              <Text
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="#333"
                                 h={"50px"}
                                 px={"16px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    if (bHomePage) {
                                       navigate("/", { state: { jump: true, type: "example" }, replace: true });
                                    } else {
                                       navigate("/", { state: { jump: true, type: "example" } });
                                    }
                                 }}>
                                 Examples
                              </Text>
                              <Divider />
                              <Text
                                 px={"16px"}
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="#333"
                                 h={"50px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    if (bHomePage) {
                                       navigate("/", { state: { jump: true, type: "price" }, replace: true });
                                    } else {
                                       navigate("/", { state: { jump: true, type: "price" } });
                                    }
                                 }}>
                                 Pricing
                              </Text>
                              <Text
                                 bg={"green.500"}
                                 px={"16px"}
                                 mb={"20px"}
                                 borderRadius={"6px"}
                                 cursor={"pointer"}
                                 fontSize={14}
                                 color="white"
                                 h={"50px"}
                                 lineHeight={"50px"}
                                 onClick={() => {
                                    setMenuShow.off();
                                    navigate("/getstart", { state: { version: FROM_NORMAL } });
                                 }}>
                                 Get Started
                              </Text>
                           </>
                        )}
                     </Flex>
                  </Flex>
               </Collapse>
            </Hide>
         </Flex>
      </>
   );
}
export default memo(loginAuth(HomeHeaderComponent, false));
