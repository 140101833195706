import { getTwitterAuthInfo } from "@/services/user";
import { useToast } from "@chakra-ui/react";
import { memo, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export default memo(function TwitterVerificationComponent() {
   const [search] = useSearchParams();
   const navigate = useNavigate();
   const toast = useToast();
   const { id, twitter_screen_name } = useSelector(
      (state) => ({
         id: state.user.id,
         twitter_screen_name: state.user.twitter_screen_name,
      }),
      shallowEqual
   );
   useEffect(() => {
      const oauth_token = search.get("oauth_token");
      const oauth_verifier = search.get("oauth_verifier");
      const denied = search.get("denied");
      if (denied) {
         toast({
            title: "Authorise Fail",
            status: "error",
            position: "top",
             duration: "3000",
         });
         navigate(`/${twitter_screen_name}/mybot`, { replace: true });
         return;
      }
      if (!oauth_token || !oauth_verifier) {
         navigate(`/${twitter_screen_name}/mybot`, { replace: true });
         return;
      }
      async function fetchData() {
         try {
            const res = await getTwitterAuthInfo(oauth_token, oauth_verifier, id);
            if (res.code === 0) {
               toast({
                  title: "Authorise Success",
                  status: "success",
                  position: "top",
                  duration: "3000",
               });
            }
            navigate(`/${twitter_screen_name}/mybot`, { replace: true });
         } catch (err) {
            navigate(`/${twitter_screen_name}/mybot`, { replace: true });
         }
      }
      fetchData();
   }, [id,navigate,search,toast,twitter_screen_name]);
});
