import { extendTheme } from "@chakra-ui/react";
import Text from "./components/text";
import Button from "./components/button";
import Form from "./components/form";
import Checkbox from "./components/checkbox";
import Divider from "./components/divider"
import Link from "./components/link";
const theme = extendTheme({
   fontSizes: {
      sm: "16px",
      md: "18px",
      lg: "20px",
      xl: "34px",
   },
   breakpoints: {          // 100%
      BREAK_PAD: "768px",    // 740px
      BREAK_LPC: "1024px",   // 960px
      BREAK_PC: "1440px", //1280px  1200px
   },
   colors: {
      aloha_blue: "#4DC2FC",
      gray: {
         200: "#E6E6E6", // line color
      },
      green: {
         500: "#24D698",
         600: "#20C189",
         700: "#20C189",
      },
      blue: {
         500: "#1DA1F2",
         600: "#1A8AD7",
         700: "#1A8AD7",
      },
      red: {
         500: "#FF4F5E",
      },
   },
   fonts: {
      body: "Arial",
   },
   shadows:{
      outline:"none",
   },
   components: {
      Text,
      Button,
      Form,
      Checkbox,
      Divider,
      Link
   },
});

export default theme;
