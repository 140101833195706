import moment from "moment-timezone";
export const isLogin = (token, id) => {
   return token && id !== 0;
};
export const truncateAddress = (address) => {
   if (!address) return "No Account";
   address = address.toLowerCase();
   const match = address.match(/^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{5})$/);
   if (!match) return address;
   return `${match[1]}…${match[2]}`;
};
export const truncateEmailAddress = (address) => {
   if (!address) return "No Account";
   if (address.length < 23) return address;
   const splits = address.split("@");
   const match = splits[0].match(/^([a-zA-Z0-9]{5})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/);
   if (!match) return address;
   return `${match[1]}…${match[2]}@${splits[1]}`;
};
export const toHex = (num) => {
   const val = Number(num);
   return "0x" + val.toString(16);
};

export const formatDateString = (date) => {
   return date.toDateString() + ", " + date.toLocaleTimeString();
};

export const getCurZoneSendTime = (timeh)=>{
   var curDateStr = moment().format('YYYY-MM-DD');
   var zeroZone = moment(curDateStr+"T"+timeh+":00:00Z");
   var curOffset = moment().utcOffset();
   var curTime = zeroZone.utcOffset(curOffset).format("HH:mm")

   if(curOffset===-420){
      return "";
   }else{
      return curTime+"(GMT"+(curOffset>0?("+"+(curOffset/60)):((curOffset/60)))+")"
   }
}

export const diff = (obj1,obj2)=>{
   var o1 = obj1 instanceof Object;
    var o2 = obj2 instanceof Object;
    if (!o1 || !o2) {
      return obj1 === obj2;
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (var o in obj1) {   
      var t1 = obj1[o] instanceof Object;
      var t2 = obj2[o] instanceof Object;
      if (t1 && t2) {
        return diff(obj1[o], obj2[o]);
      } else if (obj1[o] !== obj2[o]) {
        return false;
      }
    }
    return true;
}