import React, { memo } from "react";
import {
   Flex,
   Image,
   Text,
   Popover,
   PopoverTrigger,
   PopoverContent,
   PopoverBody,
   useDisclosure,
   StackDivider,
   Divider,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setUserInfoAction } from "@/components/profile/store/action";
import { setBotConfigListAction } from "@/components/yourbots/store/action";
import { ProfileIcon } from "@/icon/ProfileIcon";
import { RecordIcon } from "@/icon/RecordIcon";
import { LogoutIcon } from "@/icon/LogoutIcon";
import { setBidConfigListAction } from "@/components/yourbots/bid/store/action";
export default memo(function PopOverComponent() {
   const { onOpen, onClose, isOpen } = useDisclosure();
   const dispatch = useDispatch();
   const { twitter_screen_name, profile_image_url_https } = useSelector(
      (state) => ({
         twitter_screen_name: state.user.twitter_screen_name,
         profile_image_url_https: state.user.profile_image_url_https,
      }),
      shallowEqual
   );
   return (
      <Popover placement="bottom-end" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
         <PopoverTrigger>
            <Image
               alt={twitter_screen_name}
               borderRadius={"full"}
               cursor={"pointer"}
               w={"40px"}
               h={"40px"}
               ml={66}
               fallbackSrc={process.env.REACT_APP_PUBLIC_URL + "iconfont/avatar_square.svg"}
               src={profile_image_url_https}
            />
         </PopoverTrigger>
         <PopoverContent
            w={"180px"}
            bgColor="#FFF"
            border={"1px solid "}
            borderColor={"#F1F1F1"}
            outline={"none"}
            _focus={{ boxShadow: "none" }}
            boxShadow={"0px 2px 20px 10px rgba(0,0,0,0.05)"}>
            <PopoverBody p={"0px"}>
               <Flex divider={<StackDivider />} flexDir={"column"} color="#666">
                  <NavLink to={`/${twitter_screen_name}/profile`} onClick={onClose}>
                     <Flex alignItems={"center"} ml={"20px"} h={"58px"}>
                        <ProfileIcon boxSize={"16px"} />
                        <Text fontSize={"16px"} ml={"16px"}>
                           Profile
                        </Text>
                     </Flex>
                  </NavLink>
                  <Divider />
                  <NavLink to={`/${twitter_screen_name}/orderrecord`} onClick={onClose}>
                     <Flex alignItems={"center"} ml={"20px"} h={"58px"}>
                        <RecordIcon boxSize={"16px"} />
                        <Text fontSize={"16px"} ml={"16px"}>
                           Order Record
                        </Text>
                     </Flex>
                  </NavLink>
                  <Divider />
                  <NavLink
                     to={`/`}
                     onClick={(_e) => {
                        dispatch(setUserInfoAction(null));
                        dispatch(setBotConfigListAction(null));
                        dispatch(setBidConfigListAction(null))
                        onClose();
                     }}
                     replace>
                     <Flex alignItems={"center"} ml={"20px"} h={"58px"}>
                        <LogoutIcon boxSize={"16px"} />
                        <Text fontSize={"16px"} ml={"16px"}>
                           Logout
                        </Text>
                     </Flex>
                  </NavLink>
               </Flex>
            </PopoverBody>
         </PopoverContent>
      </Popover>
   );
});
