const Checkbox = {
   // The styles all button have in common
   baseStyle: {
      outline: "none", //Eliminates the default click blue border effect
   },
   sizes: {
      sm: {
         control:{
            width:'14px',
            height:'14px',
            borderWidth:'1px',
            borderColor:'#ccc',
            borderRadius:'4px',
         },
         label: { fontSize: "14px",color:'#333',marginLeft:'10px' },
      },
   },
};
export default Checkbox;
