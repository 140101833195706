import { Icon } from "@chakra-ui/react";

export const ProfileIcon = (props) => (
   <Icon viewBox="0 0 1024 1024" {...props}>
      <path
         fill="currentColor"
         d="M640 600.896a384 384 0 0 1 384 384V1024H0v-39.104a384 384 0 0 1 384-384h256z m0 128H384a256.064 256.064 0 0 0-237.056 159.168l-3.072 7.872h736.192l-3.008-7.872a256.128 256.128 0 0 0-224.256-158.848l-12.8-0.32zM489.216 0a256 256 0 0 1 12.8 511.68l-12.8 0.32a256 256 0 0 1-12.8-511.68l12.8-0.32z m0 128a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"
      />
   </Icon>
);
