import {  theme as defaultTheme } from "@chakra-ui/react";
const Button = {
    // The styles all button have in common
    baseStyle: {
        outline: 'none',  //Eliminates the default click blue border effect
    },
    variants: {
        solid: (props) => ({
            "@media(hover: none)": {
                _hover: {
                    bg: defaultTheme.components.Button.variants.solid(props).bg,
                },
            },
        }),
    },
};
export default Button;

