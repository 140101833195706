import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme";
import ResetCSS from "./theme/styles/resetcss";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Fonts from "./theme/styles/font";
const root = createRoot(document.getElementById("root"));
root.render(
   <Provider store={store}>
      <PersistGate persistor={persistor}>
         <ChakraProvider theme={theme}>
            <Fonts />
            <ResetCSS />
            <CSSReset />
            <BrowserRouter>
               <App />
            </BrowserRouter>
         </ChakraProvider>
      </PersistGate>
   </Provider>
);
