
import { combineReducers } from "redux";
import userInfoReducer from "@/components/profile/store/reducer"
import botlistReducer from '@/components/yourbots/store/reducer'
import bidReducer from '@/components/yourbots/bid/store/reducer'
import storage from 'redux-persist/lib/storage'

import {persistReducer} from 'redux-persist'
const persistConfig = {
    key:'root',
    storage,
    whitelist:["user","botlist",'bidlist']
}
const reducer = combineReducers({
    user:userInfoReducer,
    botlist:botlistReducer,
    bidlist:bidReducer
})
const persistedReducer = persistReducer(persistConfig,reducer)

export default persistedReducer