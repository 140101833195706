import { Icon } from "@chakra-ui/react";

export  const RecordIcon = (props) => (
   <Icon viewBox="0 0 1024 1024" {...props}>
      <path fill="currentColor" d="M694.4 0a128 128 0 0 1 98.624 46.4l201.6 243.84A128 128 0 0 1 1024 371.712V832a192 192 0 0 1-192 192H192a192 192 0 0 1-192-192V192a192 192 0 0 1 192-192h502.4zM896 371.776L694.4 128H192a64 64 0 0 0-63.552 56.512L128 192v640a64 64 0 0 0 56.512 63.552L192 896h640a64 64 0 0 0 63.552-56.512L896 832V371.776z" />
      <path fill="currentColor" d="M320 384m64 0l128 0q64 0 64 64l0 0q0 64-64 64l-128 0q-64 0-64-64l0 0q0-64 64-64Z" />
      <path fill="currentColor" d="M320 640m64 0l320 0q64 0 64 64l0 0q0 64-64 64l-320 0q-64 0-64-64l0 0q0-64 64-64Z" />
      <path fill="currentColor" d="M640.192 64h128v256h150.208v128h-150.208a128 128 0 0 1-127.68-118.4L640.192 320V64z" />
   </Icon>
);
