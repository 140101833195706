import { Icon } from "@chakra-ui/react";

export const LogIcon = (props) => (
   <Icon viewBox="0 0 1024 1024" {...props}>
      <path d="M491.413333 28.16a25.109333 25.109333 0 0 1 45.226667 21.632l-1.173333 2.474667-51.413334 93.930666h90.922667c17.194667 0 29.034667 16.768 23.936 32.682667l-0.896 2.389333-53.781333 124.458667a25.109333 25.109333 0 0 1-47.04-17.344l0.938666-2.56 38.634667-89.429333h-95.04c-18.197333 0-30.08-18.602667-23.146667-34.837334l1.109334-2.304 71.722666-131.093333z" fill="#24D698"></path>
      <path d="M451.776 55.296a55.296 55.210667 90 1 0 110.421333 0 55.296 55.210667 90 1 0-110.421333 0Z" fill="#24D698"></path>
      <path d="M772.992 271.424a120.512 120.512 0 0 1 120.490667 120.490667v321.92a120.512 120.512 0 0 1-120.490667 120.512H261.056a120.512 120.512 0 0 1-120.512-120.512v-321.92a120.512 120.512 0 0 1 120.512-120.490667h511.936z m0 90.368H261.056c-15.658667 0-28.522667 11.946667-29.994667 27.221333l-0.128 2.901334v321.92c0 15.658667 11.946667 28.544 27.221334 29.994666l2.901333 0.149334h511.936c15.658667 0 28.522667-11.946667 29.973333-27.242667l0.149334-2.901333v-321.92c0-15.658667-11.946667-28.522667-27.221334-29.994667l-2.901333-0.128z" fill="#FF8D35"></path>
      <path d="M933.653333 447.338667m45.184 0l-0.021333 0q45.184 0 45.184 45.184l0 120.725333q0 45.184-45.184 45.184l0.021333 0q-45.184 0-45.184-45.184l0-120.725333q0-45.184 45.184-45.184Z" fill="#24D698" ></path>
      <path d="M0 447.338667m45.184 0l-0.021333 0q45.184 0 45.184 45.184l0 120.725333q0 45.184-45.184 45.184l0.021333 0q-45.184 0-45.184-45.184l0-120.725333q0-45.184 45.184-45.184Z" fill="#24D698"></path>
      <path d="M682.282667 872.426667a45.184 45.184 0 1 1 50.965333 74.624c-53.717333 36.672-134.506667 58.133333-220.309333 58.133333-87.68 0-169.216-21.12-222.4-57.877333a45.184 45.184 0 0 1 48.405333-76.245334l2.986667 1.92c36.629333 25.301333 100.437333 41.813333 171.008 41.813334 68.693333 0 131.818667-16.746667 169.344-42.368z" fill="#FF8D35"></path>
      <path d="M321.258667 552.874667a70.357333 70.272 90 1 0 140.544 0 70.357333 70.272 90 1 0-140.544 0Z" fill="#000000"></path>
      <path d="M572.245333 552.874667a70.357333 70.272 90 1 0 140.544 0 70.357333 70.272 90 1 0-140.544 0Z" fill="#000000"></path>
   </Icon>
);

