import {  getBotBidConfigList } from "@/services/bot";
import { SET_BID_CONFIG_LIST } from "./constants";

export const getBidConfigListAction = (user_id) => {
   return (dispatch) => {
    getBotBidConfigList(user_id).then((res) => {
         if(res.code===0){
            if(res.data.length>0){
               dispatch(setBidConfigListAction(res.data));
            }else{
               dispatch(setBidConfigListAction(null));
            }
         }
      });
   };
};
export const setBidConfigListAction = (res) => ({
   type: SET_BID_CONFIG_LIST,
   res,
});
