import { Global } from "@emotion/react";
const Fonts = () => (
   <Global
      styles={`
      
      @font-face {
        font-family: 'Arial-BoldMT';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/assets/fonts/Arial-BoldMT.otf') ;
      }
      `}
   />
);

export default Fonts;
