import request from "./request"
export const getTwitterUrl = () =>{
    var config = {
        method:'get',
        url :'/user/login/twitter/url',
    }
    return request(config);
}

export const getTwitterAuthUrl = (user_id) =>{
    var config = {
        method:'get',
        url :`/user/auth/twitter/url?user_id=${user_id}`,
    }
    return request(config);
}

export const getTwitterInfo = (oauth_token,oauth_verifier)=>{
    var config = {
        method:'get',
        url :'/user/login/twitter/info',
        params:{
            oauth_token:oauth_token,
            oauth_verifier:oauth_verifier
        }
    }
    return request(config)
}
export const getTwitterAuthInfo = (oauth_token,oauth_verifier,id)=>{
    var config = {
        method:'get',
        url :'/user/auth/twitter/token',
        params:{
            oauth_token:oauth_token,
            oauth_verifier:oauth_verifier,
            user_id:id
        }
    }
    return request(config)
}
export const bindEmailUrl = (user_id,email_address)=>{
    var config = {
        method:'get',
        url:'/user/bind/email',
        params:{
            user_id:user_id,
            email_address:email_address,
        }
    }
    return request(config);
}

export const validateEmail = (code)=>{
    var config = {
        method:'get',
        url:'/user/bind/email/validate',
        params:{
            code:code,
        }
    }
    return request(config);
}
export const getUserInfo = (user_id)=>{
    var config = {
        method:'get',
        url:'/user/info',
        params:{
            user_id:user_id,
        }
    }
    return request(config);
}
export const bindWallet = (user_id,address,raw_msg,msg_signature)=>{
    const formData = new FormData()
    formData.append('user_id',user_id);
    formData.append('address',address)
    formData.append('raw_msg',raw_msg);
    formData.append('msg_signature',msg_signature);
    return request.post('/user/bind/wallet',formData)
}

export const unBindWallet = (user_id)=>{
    const formData = new FormData()
    formData.append('user_id',user_id);
    return request.post('/user/unbind/wallet',formData)
}

export const setVersionLevel = (user_id,vip_level)=>{
    const formData = new FormData()
    formData.append('user_id',user_id)
    formData.append('vip_level',vip_level)
    return request.post('/user/set/level',formData)
}
