import styled from "styled-components";
export const DivBox = styled.div`
   background-color: transparent;
   display: flex;
   user-select: none;
   justify-content: space-between;
   flex-direction: row;
   align-items: center;
   .navlink {
      height: 85px;
      color: #000;
      font-size: 18px;
      line-height: 85px;
      &.active {
         border-bottom: 3px solid #24D698;
      }
   }
   .navlink:hover{
      color:#24D698
   }
`;
