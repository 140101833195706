import { SET_USER_INFO, SET_USER_LEVEL } from "./constants";
const defaultState = {
   id: 0,
   create_time: 0,
   email_address: "",
   profile_image_url_https: "",
   twitter_id: 0,
   daily_max_send_count:0,
   daily_max_support_count:0,
   twitter_screen_name: "",
   twitter_username: "",
   vip_expire_time: 0,
   vip_expire_sec: 0,
   wallet_address: "",
   vip_level: 0,
   has_custom_contract:0,
};

function userInfoReducer(state = defaultState, action) {
   switch (action.type) {
      case SET_USER_INFO:
         if (action.res === null) {
            window.localStorage.removeItem('token')
            return defaultState;
         } else {
            return action.res;
         }
      case SET_USER_LEVEL:
         return { ...state, vip_level: action.res }
      default:
         return state;
   }
}
export default userInfoReducer;
