import TwitterVerification from "@/components/callback/twitter-verification";
import { Center, Spinner } from "@chakra-ui/react";
import React, { lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const Loadable = (Component) => (props) => {
   return (
      <Suspense
         fallback={
            <Center mt={"300px"} h={"100%"}>
               <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="green.500" size="xl" />
            </Center>
         }>
         <Component {...props} />
      </Suspense>
   );
};

export default function RouterConfig() {
   return useRoutes([
      {
         path: "/",
         exact: true,
         element: <HomeComponent />,
      },
      {
         path: "*",
         exact:true,
         element: <Navigate to="/" replace />,
      },
      {
         path: "/getstart",
         element: <GetStartComponent />,
      },
      {
         path: "/:twittername/mybot",
         element: <YourBotsLayoutComponent />,
         exact: true,
         children: [
            {
               path: "/:twittername/mybot",
               exact: true,
               element: <MyBotComponent />,
            },
            {
               path: "/:twittername/mybot/set",
               element: <SetBotComponent />,
            },
         ],
      },
      {
         path: "/:twittername/orderrecord",
         element: <OrderRecordComponent />,
      },
      {
         path: "/:twittername/profile",
         element: <ProfileComponent />,
      },
      {
         path: "/:twittername/pay",
         element: <PayComponent />,
      },
      {
         path: "/:twittername/pay/payresult",
         element: <PayResultComponent />,
      },
      {
         path: "/doc/privacy",
         element: <PrivacyPolicyComponent />,
      },
      {
         path: "/doc/termsofservice",
         element: <TermsOfServiceComponent />,
      },
      {
         path: "/twitter_auth",
         element: <TwitterAuthComponent />,
      },
      {
         path: "/twitter_verification",
         element: <TwitterVerification />,
      },
      {
         path: "/bind_email",
         element: <EmailValidateComponent />,
      },
   ]);
}

const HomeComponent = Loadable(lazy(() => import("../components/home")));
const GetStartComponent = Loadable(lazy(() => import("../components/getstart")));
const SetBotComponent = Loadable(lazy(() => import("../components/yourbots/setbot")));
const OrderRecordComponent = Loadable(lazy(() => import("../components/order-record")));
const ProfileComponent = Loadable(lazy(() => import("../components/profile")));
const PayComponent = Loadable(lazy(() => import("../components/pay")));
const PayResultComponent = Loadable(lazy(() => import("../components/pay/pay-result")));
const MyBotComponent = Loadable(lazy(() => import("../components/yourbots/mybot")));
const YourBotsLayoutComponent = Loadable(lazy(() => import("../components/yourbots")));
const PrivacyPolicyComponent = Loadable(lazy(() => import("../components/docx/privacy")));
const TermsOfServiceComponent = Loadable(lazy(() => import("../components/docx/termservice")));
const TwitterAuthComponent = Loadable(lazy(() => import("../components/callback/twitter-auth")));
const EmailValidateComponent = Loadable(lazy(() => import("../components/callback/email-validate")));
