import { LIMIT_COUNT } from "./config";
import request from "./request";
export const getBotConfigList = (user_id) => {
   var config = {
      method: "get",
      url: "/bot/config/list",
      params: {
         user_id: user_id,
      },
   };
   return request(config);
};

export const getBotImageSign = (suffix,user_id)=>{
   var config = {
      method: "get",
      url: "/upload/avatar/presigned",
      params: {
         suffix: suffix,
         user_id:user_id
      },
   };
   return request(config);
}
export const getBotBidConfigList = (user_id) => {
   var config = {
      method: "get",
      url: "/bot/bid/config/list",
      params: {
         user_id: user_id,
      },
   };
   return request(config);
};
export const editBotConfig = (user_id,vip_level,botInfo) => {
   const formData = new FormData()
   formData.append('vip_level',vip_level)
   formData.append('user_id',user_id);
   formData.append('id',botInfo.id);
   formData.append('token_contract_address',botInfo.token_contract_address);
   formData.append('price_begin',botInfo.price_begin);
   formData.append('token_contract_name',botInfo.token_contract_name);
   formData.append('msg_tag',botInfo.msg_tag);
   formData.append('status',botInfo.status);
   formData.append('is_need_from_to',botInfo.is_need_from_to);
   formData.append('is_need_image',botInfo.is_need_image);
   formData.append('prefix_str',botInfo.prefix_str);
   formData.append('hot_ratio',botInfo.hot_ratio);
   formData.append('host_prefix',botInfo.host_prefix);
   formData.append('discord_status',botInfo.discord_status)
   formData.append('send_top_report',botInfo.send_top_report);
   formData.append('discord_webhook',botInfo.discord_webhook)
   formData.append('discord_bot_name',botInfo.discord_bot_name)
   formData.append('discord_bot_avatar',botInfo.discord_bot_avatar)
   formData.append('discord_img_size',botInfo.discord_img_size.toString())
   return request.post('/bot/config/edit',formData)
};
export const editBidConfig = (
   user_id,
   vip_level,
   bidInfo,
   botInfo,
) => {
   const formData = new FormData()
   formData.append('token_contract_address',botInfo.token_contract_address);
   formData.append('id',bidInfo.id);
   formData.append('user_id',user_id);
   formData.append('price_begin',bidInfo.price_begin);
   formData.append('token_contract_name',botInfo.token_contract_name);
   formData.append('msg_tag',bidInfo.msg_tag);
   formData.append('status',bidInfo.status);
   formData.append('is_need_image',bidInfo.is_need_image);
   formData.append('bid_sync_open',bidInfo.bid_sync_open)
   formData.append('prefix_str',bidInfo.prefix_str);
   formData.append('vip_level',vip_level)
   formData.append('discord_status',bidInfo.discord_status)
   formData.append('discord_webhook',bidInfo.discord_webhook)
   formData.append('discord_bot_name',bidInfo.discord_bot_name)
   formData.append('discord_bot_avatar',bidInfo.discord_bot_avatar)
   formData.append('discord_img_size',bidInfo.discord_img_size.toString())
   return request.post('/bot/bid/config/edit',formData)
};
export const delBotConfig = (
   id,
   user_id,
) => {
   const formData = new FormData()
   formData.append('id',id);
   formData.append('user_id',user_id);
   return request.post('/bot/config/delete',formData)
};
export const delBidConfig = (
   id,
   user_id,
) => {
   const formData = new FormData()
   formData.append('id',id);
   formData.append('user_id',user_id);
   return request.post('/bot/bid/config/delete',formData)
};
export const getBotSendList = (config_id,user_id, offset) => {
   var config = {
      method: "get",
      url: "/bot/twitter/send/list",
      params: {
         config_id:config_id,
         user_id: user_id,
         offset:offset,
         limit:LIMIT_COUNT
      },
   };
   return request(config);
};
export const getBidSendList = (config_id,user_id, offset) => {
   var config = {
      method: "get",
      url: "/bot/bid/twitter/send/list",
      params: {
         config_id:config_id,
         user_id: user_id,
         offset:offset,
         limit:LIMIT_COUNT
      },
   };
   return request(config);
};