import { setUserInfoAction } from "@/components/profile/store/action";
import {  getBotConfigList } from "@/services/bot";
import { CHANGE_SELECT_BOT_ID, SET_BOT_CONFIG_LIST } from "./constants";

export const getBotConfigListAction = (user_id,botId) => {
   return (dispatch) => {
      getBotConfigList(user_id).then((res) => {
         if(res.code===0){
            if(res.data.length>0){
               dispatch(setBotConfigListAction(res.data));
               if(botId){
                  dispatch(setSelectBotIdAction(botId))
               }
            }else{
               dispatch(setBotConfigListAction(null,0));
            }
         }else{
         
            dispatch(setUserInfoAction(null,0))
         }
      });
   };
};
export const setBotConfigListAction = (res) => ({
   type: SET_BOT_CONFIG_LIST,
   res,
});
export const setSelectBotIdAction = (res)=>({
   type:CHANGE_SELECT_BOT_ID,
   res
})